import React, { FC, useEffect, useState } from "react";
import styles from "./PopUp.module.scss";

export interface IPopUp {
  period?: string;
  car?: string;
  tariff?: string;
  show: boolean;
  random?: number;
  x?: number;
  y?: number;
}

const PopUp: FC<IPopUp> = ({ period, car, tariff, show, random, x, y }) => {
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside, false);
  }, []);

  useEffect(() => {
    setDisplay(show);
  }, [show, random]);

  const handleClickOutside = (e: MouseEvent) => {
    setDisplay(false);
  };
  const top = y ? y - 80: 0;
  // const left = x ? (x < 220 ? 10 : x - 240) : 0;
  const left = 10;
  const topT = y ? y - 10 : 0;
  const leftT = x ? x + 10 : 0;
  return (
    <React.Fragment>
      {display && (
        <React.Fragment>
          <div className={styles.triangle} style={{ top: topT, left: leftT }} />
          <div className={styles.popup} style={{ top, left }}>
            <div>{period}</div>
            <div>{car}</div>
            <div>{tariff}</div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PopUp;