import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Select from "../../components/Select";

import styles from "./index.module.scss";
import { IShifts, IPeriod, IShift, IOrder } from "../../models";

const periods: Array<[string, any]> = [
  [
    "Текущие сутки",
    {
      bgn: moment()
        .startOf("day")
        .toDate(),
      end: moment()
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Прошедшие сутки",
    {
      bgn: moment()
        .add(-1, "day")
        .startOf("day")
        .toDate(),
      end: moment()
        .add(-1, "day")
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Текущая неделя",
    {
      bgn: moment()
        .startOf("week")
        .toDate(),
      end: moment()
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Прошедшая неделя",
    {
      bgn: moment()
        .add(-1, "week")
        .startOf("week")
        .toDate(),
      end: moment()
        .add(-1, "week")
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Текущий месяц",
    {
      bgn: moment()
        .startOf("month")
        .toDate(),
      end: moment()
        .endOf("month")
        .toDate()
    }
  ],
  [
    "Прошедший месяц",
    {
      bgn: moment()
        .add(-1, "month")
        .startOf("month")
        .toDate(),
      end: moment()
        .add(-1, "month")
        .endOf("month")
        .toDate()
    }
  ]
];
const groups: Array<[string, any]> = [
  ["Сеансы", ""],
  ["Путевые листы", "shifts"]
];

const Route: FC<{ src: string; dst: string; count: number }> = ({
  src,
  dst,
  count
}) => {
  return (
    <div className={styles.route}>
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{src}</div>
      </div>
      {count > 2 && (
        <div className={styles.addsContainer}>
          <div className={styles.iconContainer}>
            <Icon icon={faDotCircle} className={styles.icon} />
          </div>
          <div className={styles.adds}>{`${count -
            2} промежуточных адреса`}</div>
        </div>
      )}
      <div className={styles.addsContainer}>
        <div className={styles.iconContainer}>
          <Icon icon={faDotCircle} className={styles.icon} />
        </div>
        <div className={styles.adds}>{dst || "Не определён"}</div>
      </div>
    </div>
  );
};

const Order: FC<IOrder> = ({
  id,
  assignedAt,
  doneAt,
  cost,
  costCorrected,
  costDriver,
  costDriverCorrected,
  addressSourceTitle,
  addressDestTitle,
  routeActualCount
}) => {
  const duration = moment
    // @ts-ignore
    .duration(moment(assignedAt) - moment(doneAt))
    .humanize();
  const cost_ = costCorrected || cost;
  const costDriver_ = costDriverCorrected || costDriver;
  return (
    <div className={styles.order}>
      <div className={styles.line}>
        <div className={styles.time}>
          {moment(assignedAt).format("HH:mm")}-{moment(doneAt).format("HH:mm")}
        </div>
        <div className={styles.cost}>
          {costDriver_ >= 0
            ? `${cost_ || 0} / ${costDriver_} ₽`
            : `${cost_ || 0} + ${-(costDriver_ || 0)} ₽`}
        </div>
      </div>
      <div className={styles.tiny}>
        {duration} №{id}
      </div>
      <Route
        src={addressSourceTitle}
        dst={addressDestTitle}
        count={routeActualCount}
      />
    </div>
  );
};

interface IDayStat {
  shift: IShift;
}

const DayStat: FC<IDayStat> = ({ shift }) => {
  return (
    <React.Fragment>
      <div className={styles.title}>
        {moment(shift.start).format("DD MMM HH:mm")}-
        {moment(shift.end).format("HH:mm")}
      </div>
      <div className={styles.orders}>
        {shift.orders.map((i, k) => (
          <Order key={k} {...i} />
        ))}
      </div>
    </React.Fragment>
  );
};

const Orders: FC = () => {
  const groupByIndex = data.getGroupBy();
  const groupByValue = groups[groupByIndex][1];
  const [groupBy, setGroupBy] = useState<"shift" | undefined>(groupByValue);
  const periodIndex = data.getPeriod();
  const periodValue = periods[periodIndex][1];
  const [period, setPeriod] = useState<IPeriod>(periodValue);
  const [shifts, setShifts] = useState<IShifts | null>();
  useEffect(() => {
    data.getOrders(period, groupBy).then(setShifts);
  }, [period, groupBy]);
  return (
    <BasicFrame title="Заказы">
      <div className={styles.container}>
        <Select
          title="Период"
          items={periods}
          selected={periodIndex}
          onSelect={(i: any, k: number) => {
            setPeriod(i);
            data.setPeriod(k);
          }}
        />
        <Select
          title="Группировка"
          items={groups}
          selected={groupByIndex}
          inSeries={true}
          onSelect={(i: any, k: number) => {
            setGroupBy(i);
            data.setGroupBy(k);
          }}
        />
        {shifts &&
          shifts.data
            .sort((a, b) => (a.start > b.start ? -1 : 1))
            .map((i: IShift, k: number) => <DayStat key={k} shift={i} />)}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button onClick={e => history.push("/statistics")}>Статистика</button>
      </div>
    </BasicFrame>
  );
};

export default Orders;
