import React, { FC } from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";

import styles from "./Loader.module.scss";


const Loader: FC = () => {
  return (
    <div className={styles.wait}>
      <Icon className={styles.icon} icon={faHourglassHalf} />
    </div>
  );
};

export default Loader;