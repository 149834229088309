import React, { FC, useEffect, useState } from "react";
import moment from "moment";

import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Select from "../../components/Select";

import styles from "./index.module.scss";
import { IPeriod } from "../../models";

const periods: Array<[string, any]> = [
  [
    "Текущие сутки",
    {
      bgn: moment()
        .startOf("day")
        .toDate(),
      end: moment()
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Прошедшие сутки",
    {
      bgn: moment()
        .add(-1, "day")
        .startOf("day")
        .toDate(),
      end: moment()
        .add(-1, "day")
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Текущая неделя",
    {
      bgn: moment()
        .startOf("week")
        .toDate(),
      end: moment()
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Прошедшая неделя",
    {
      bgn: moment()
        .add(-1, "week")
        .startOf("week")
        .toDate(),
      end: moment()
        .add(-1, "week")
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Текущий месяц",
    {
      bgn: moment()
        .startOf("month")
        .toDate(),
      end: moment()
        .endOf("month")
        .toDate()
    }
  ],
  [
    "Прошедший месяц",
    {
      bgn: moment()
        .add(-1, "month")
        .startOf("month")
        .toDate(),
      end: moment()
        .add(-1, "month")
        .endOf("month")
        .toDate()
    }
  ]
];

const BalanceHistory: FC = () => {
  const periodIndex = data.getPeriod();
  const periodValue = periods[periodIndex][1];
  const [period, setPeriod] = useState<IPeriod>(periodValue);
  useEffect(() => {
    data.getBalanceHistory(period).then(console.log);
  }, [period]);
  return (
    <BasicFrame title="Пополнения">
      <div className={styles.container}>
        <Select
          title="Период"
          items={periods}
          selected={periodIndex}
          onSelect={(i: any, k: number) => {
            setPeriod(i);
            data.setPeriod(k);
          }}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
      </div>
    </BasicFrame>
  );
};

export default BalanceHistory;
