import api from "./index";

export class MessageAPI {
  static async sendEmail(email: string, message: string): Promise<any> {
    return api.request('POST', '/user/mail', {
      body: { email, message }
    })
  }

  static async sendTask(summary: string, description: string): Promise<any> {
    return api.request('POST', '/user/jira', {
      body: { summary, description }
    })
  }

  static async sendMech(summary: string, description: string): Promise<any> {
    return api.request('POST', '/user/jiraMech', {
      body: { summary, description }
    })
  }
}