import { action } from "mobx";
import _ from "lodash";
import moment from "moment";
import allSettled from "promise.allsettled";
import { ui, account, cache } from "./index";

import { DataAPI as api } from "../api/data";
import {
  IBalance,
  IShifts,
  IPeriod,
  IAccount,
  IFlows,
  ICar,
  IPlanRecords,
  IPaymentFor,
  ITransfer,
  ICarPossibility,
  ICarPossibilities,
  ICarPossibilityType,
  IOrder,
} from "../models";

const CACHE_TIME = 1000 * 60 * 60;

class Data {
  @action.bound
  async getBalance(): Promise<IBalance | undefined> {
    ui.loading = true;
    let result;
    let accounts;
    try {
      if (account.user && account.user.yandexId) {
        const [balance, yandexBalance] = await allSettled([
          api.getBalance(),
          api.getYandexBalance(),
        ]);
        if (balance.status !== "rejected") {
          result = { ...balance.value };
        } else {
          console.error(balance);
          ui.setMessage({
            text: `Ошибка при получении баланса.<br/> "${
              (balance.reason as Error).message
            }"`,
            lifeTime: 5000,
            type: "ERROR",
          });
          result = {};
        }
        if (yandexBalance.status !== "rejected") {
          if (yandexBalance.value.sum) {
            result = {
              ...result,
              sumYandex: yandexBalance.value.sum,
            };
          } else {
            result = {
              ...result,
              cityMobilBalance: yandexBalance.value.balance,
              cityMobilFullBalance: yandexBalance.value.full_balance,
              cityMobilTransitBalance: yandexBalance.value.transit_balance,
            };
          }
        } else {
          console.error(yandexBalance);
          ui.setMessage({
            text: `Ошибка при получении баланса от Партнёра.<br/> "${
              (yandexBalance.reason as Error).message
            }"`,
            lifeTime: 5000,
            type: "ERROR",
          });
          result = { ...result, sumYandex: 0 };
        }
      } else {
        result = await api.getBalance();
      }
      accounts = await api.getAccounts();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    const ignoringFields = ["sum", "history"];
    if (
      account &&
      account.user &&
      account.user.garage &&
      account.user.garage.id === 2447
    ) {
      ignoringFields.push("sumRent");
    }
    if (accounts) {
      const CAR_RENT = accounts.find((i) => i.type === "CAR_RENT");
      const ORDERS = accounts.find((i) => i.type === "ORDERS");
      const SUBSCRIPTION = accounts.find((i) => i.type === "SUBSCRIPTION");
      if (!CAR_RENT) {
        ignoringFields.push("sumRent");
      }
      if (!ORDERS) {
        ignoringFields.push("sumOrders");
      }
      if (!SUBSCRIPTION) {
        ignoringFields.push("sumAbon");
        ignoringFields.push("sumSubscription");
      }
    }
    ui.loading = false;
    // @ts-ignore
    return result ? _.omit(result, ignoringFields) : result;
  }

  @action.bound
  async getBalanceHistory(period: IPeriod): Promise<any> {
    ui.loading = true;
    let result;
    try {
      result = await api.getBalanceHistory(period);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result ? _.omit(result, ["sum"]) : result;
  }

  @action.bound
  async transfer(transfer: ITransfer): Promise<any> {
    ui.loading = true;
    let result;
    try {
      result = await api.transfer(transfer);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  @action.bound
  async getAvailablePayments(): Promise<IPaymentFor[] | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.getAvailablePayments();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  @action.bound
  async getCars(): Promise<ICar[] | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.getCars();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  @action.bound
  async getOrders(
    period: IPeriod,
    groupBy?: "shift" | ""
  ): Promise<IShifts | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.getOrders(period, groupBy);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async getAccounts(): Promise<IAccount[] | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.getAccounts();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async getFlows(
    period: IPeriod,
    accountId?: number
  ): Promise<IFlows | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.getFlows(period, accountId);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async getPlanRecords(
    period: IPeriod,
    carId?: number
  ): Promise<IPlanRecords | undefined> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.getPlanRecords(period, carId);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async findWashPossibilities(date: string, garageId: number): Promise<any> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.findWashPossibilities(date, garageId);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async findWashPossibilitiesAll(period: IPeriod, carId: number): Promise<any> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.findWashPossibilitiesAll(period, carId);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async findCarPossibilities(
    period: IPeriod,
    carId: number,
    types?: ICarPossibilityType[]
  ): Promise<ICarPossibilities | undefined> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.findCarPossibilities(period, carId, types);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async removeCarPossibility(id: number): Promise<any | undefined> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.removeCarPossibility(id);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async addCarPossibility(body: ICarPossibility): Promise<any | undefined> {
    ui.loading = true;
    let result;
    try {
      // await wait(1000);
      result = await api.addCarPossibility({ calc: false, ...body });
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async getReservedOrders(
    showLoading = true,
    useCache = true
  ): Promise<any | undefined> {
    if (showLoading) {
      ui.loading = true;
    }
    let result;
    try {
      result = await cache(api.getRevervedOrders, useCache ? CACHE_TIME : 0);
    } catch (err) {
      // ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    if (showLoading) {
      ui.loading = false;
    }
    return result;
  }

  async getReservedOrdersList(
    showLoading = true,
    useCache = true
  ): Promise<any | undefined> {
    if (showLoading) {
      ui.loading = true;
    }
    let result;
    try {
      result = await api.getReservedOrdersList();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    if (showLoading) {
      ui.loading = false;
    }
    return result;
  }

  async createReservedOrdersRequest(order: IOrder): Promise<any | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.createReservedOrdersRequest({ orderId: order.id });
      ui.setMessage({
        text: `Заявка на заказ ${order.addressSourceTitle} к ${moment(
          order.assignedAt
        ).format("HH:mm dddd DD MMM YYYY")} успешно отправлена`,
        lifeTime: 5000,
        type: "INFO",
      });
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async removeReservedOrdersRequest(
    requestId: number
  ): Promise<any | undefined> {
    ui.loading = true;
    let result;
    try {
      result = await api.removeReservedOrdersRequest(requestId);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  setGroupBy(i: number) {
    localStorage.setItem("groupBy", `${i}`);
  }
  getGroupBy(): number {
    return parseInt(localStorage.getItem("groupBy") || "0");
  }

  setPeriod(i: number) {
    return localStorage.setItem("period", `${i}`);
  }
  getPeriod(): number {
    return parseInt(localStorage.getItem("period") || "2");
  }
}

export const data = new Data();
