import React, { FC, useEffect, useState } from "react";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { history, data } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Select from "../../components/Select";

import styles from "./index.module.scss";
import { IPeriod, IFlow, IFlows, IAccount } from "../../models";
import { Dictionary } from "lodash";

const periods: Array<[string, any]> = [
  [
    "Текущие сутки",
    {
      bgn: moment()
        .startOf("day")
        .toDate(),
      end: moment()
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Прошедшие сутки",
    {
      bgn: moment()
        .add(-1, "day")
        .startOf("day")
        .toDate(),
      end: moment()
        .add(-1, "day")
        .endOf("day")
        .toDate()
    }
  ],
  [
    "Текущая неделя",
    {
      bgn: moment()
        .startOf("week")
        .toDate(),
      end: moment()
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Прошедшая неделя",
    {
      bgn: moment()
        .add(-1, "week")
        .startOf("week")
        .toDate(),
      end: moment()
        .add(-1, "week")
        .endOf("week")
        .toDate()
    }
  ],
  [
    "Текущий месяц",
    {
      bgn: moment()
        .startOf("month")
        .toDate(),
      end: moment()
        .endOf("month")
        .toDate()
    }
  ],
  [
    "Прошедший месяц",
    {
      bgn: moment()
        .add(-1, "month")
        .startOf("month")
        .toDate(),
      end: moment()
        .add(-1, "month")
        .endOf("month")
        .toDate()
    }
  ]
];
const types: Array<[string, any]> = [
  ["Все", 0],
  ["Пополнение", 1],
  ["Списание", -1]
];
const accountTypes: Dictionary<string> = {
  ORDERS: "Заказы",
  SUBSCRIPTION: "Абон. плата",
  FORFEIT: "Штрафы",
  CAR_RENT: "Аренда авто",
  PRETRIPINSPECTION: "Предрейсовые осмотры"
};

const Flow: FC<IFlow> = ({ id, createdAt, value, remarks, code, receipt }) => {
  return (
    <div
      className={styles.flowContainer}
      onClick={
        receipt
          ? e => {
              window.open(receipt, "_blank");
            }
          : e => null
      }
    >
      <div className={styles.receipt}>
        {receipt ? <Icon icon={faReceipt} /> : <div />}
      </div>
      <div className={styles.flowDescription}>
        <div className={styles.flowTitle}>
          <div className={styles.flowNumber}>№{id}</div>
          <div>{moment(createdAt).format("DD MMM HH:mm")}</div>
        </div>
        <div className={styles.remarks}>
          {remarks
            ? remarks
            : code === "TRANSFER"
            ? "Перевод между счетами"
            : ""}
        </div>
      </div>
      <div className={[styles.cost, value < 0 && styles.negative].join(" ")}>
        {value / 100} ₽
      </div>
    </div>
  );
};

interface IGroups {
  [prop: string]: any;
}

const Statiscics: FC<{
  flows: IFlows | undefined;
  account: IAccount | null | undefined;
}> = ({ flows, account }) => {
  return (
    <React.Fragment>
      {account && flows && (
        <React.Fragment>
          <div className={styles.title}>Движение средств</div>
          <dl className={styles.balance}>
            {account.id !== -1 && (
              <React.Fragment>
                <dt>Баланс счёта</dt>
                <dd
                  className={[
                    styles.cost,
                    account.value < 0 && styles.negative
                  ].join(" ")}
                >
                  {`${account.value / 100} ₽`}
                </dd>
              </React.Fragment>
            )}
            <dt>Сумма пополнений</dt>
            <dd className={styles.cost}>
              {flows.data.reduce((s, i) => (i.value > 0 ? s + i.value : s), 0) /
                100}{" "}
              ₽
            </dd>
            {account.id !== -1 && (
              <React.Fragment>
                <dt>Сумма списаний</dt>
                <dd className={[styles.cost, styles.negative].join(" ")}>
                  {flows.data.reduce(
                    (s, i) => (i.value < 0 ? s - i.value : s),
                    0
                  ) / 100}{" "}
                  ₽
                </dd>
              </React.Fragment>
            )}
          </dl>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const FlowListByDate: FC<{ flowItems: IFlow[] }> = ({ flowItems }) => {
  const groups: IGroups = flowItems.reduce((s: IGroups, i) => {
    const index = moment(i.createdAt).format("DD MMMM YYYY");
    if (!s[index]) {
      s[index] = [];
    }
    s[index].push(i);
    return s;
  }, {});
  return (
    <div>
      {Object.keys(groups).map(g => {
        return (
          <React.Fragment key={g}>
            <div className={styles.title}>{g}</div>
            {groups[g]
              .sort((a: IFlow, b: IFlow) =>
                a.createdAt < b.createdAt ? -1 : 1
              )
              .map((i: IFlow, k: number) => (
                <Flow key={k} {...i} />
              ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Finances: FC = () => {
  const periodIndex = data.getPeriod();
  const periodValue = periods[periodIndex][1];
  const [period, setPeriod] = useState<IPeriod>(periodValue);
  const [accounts, setAccounts] = useState<IAccount[] | undefined>();
  const [flows, setFlows] = useState<IFlows | undefined>();
  const [flowItems, setFlowItems] = useState<IFlow[] | []>(
    flows ? flows.data : []
  );
  const [accountId, setAccountId] = useState(undefined);
  const [flowType, setFlowType] = useState(0);

  useEffect(() => {
    data.getAccounts().then(accounts => {
      if (accounts && accounts.length) {
        setAccounts(
          accounts.map(i => {
            return { ...i, type: accountTypes[i.type] || i.type };
          })
        );
        setAccountId(accounts[0].id);
      }
    });
  }, []);

  useEffect(() => {
    if (accountId) {
      data.getFlows(period, accountId).then(setFlows);
    }
  }, [period, accountId]);

  useEffect(() => {
    if (flows) {
      setFlowItems(
        flows.data.filter(f => f.value * parseInt("" + flowType) >= 0)
      );
    }
  }, [flows, flowType]);

  return (
    <BasicFrame title="Финансы">
      <div className={styles.container}>
        <Select
          title="Период"
          items={periods}
          selected={periodIndex}
          onSelect={(i: any, k: number) => {
            data.setPeriod(k);
            setPeriod(i);
          }}
        />
        <Select
          title="Счета"
          items={accounts ? accounts.map(i => [i.type, i.id]) : []}
          selected={0}
          onSelect={(i: any) => setAccountId(i)}
        />
        <Select
          title="Тип"
          items={types}
          selected={flowType}
          inSeries={true}
          onSelect={(i: any, k: number) => setFlowType(i)}
        />
        <Statiscics
          flows={flows}
          account={
            accounts && accountId
              ? accounts.find(i => i.id === accountId)
              : null
          }
        />
        <FlowListByDate flowItems={flowItems} />
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
      </div>
    </BasicFrame>
  );
};

export default Finances;
