import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { history, data, account } from "../../store";
import BasicFrame from "../../components/BasicFrame";
import Dialog from "../../components/Dialog";

import styles from "./index.module.scss";
import { IBalance, IPaymentFor } from "../../models";

const balanceDict: { [props: string]: any } = {
  sumYandex: "Яндекс Такси",
  cityMobilBalance: "СитиМобил",
  cityMobilFullBalance: "СитиМобил полный",
  cityMobilTransitBalance: "СитиМобил транзит",
  sumOrders: "За заказы",
  sumAbon: "Абонентская плата",
  sumSubscription: "Ожидаемое списание",
  sumRent: "Аренда",
  sumRefund: "Возврат платежа",
  forfeit: "Штрафы",
  limit: "Лимит",
};

interface ILine {
  title: string;
  subTitle?: string;
  value: number;
}

const MIN_SUM = 1;

function checkPaymentsAvailable(from: IPaymentFor, to: IPaymentFor): boolean {
  return to.allowFrom
    ? to.allowFrom.includes(from.name)
    : from.name !== to.name && to.to;
}

const Line: FC<ILine> = ({ title, subTitle, value }) => (
  <React.Fragment>
    {title === "sumSubscription" ? (
      <div className={styles.subLine}>
        <div className={styles.title}>{balanceDict[title] || title}</div>
        <div className={[styles.value, styles.subscription].join(" ")}>
          {value}
        </div>
      </div>
    ) : (
      <div className={styles.line}>
        <div className={styles.title}>
          {balanceDict[title] || title} {subTitle && <small>{subTitle}</small>}
        </div>
        <div className={[styles.value, value <= 0 && styles.subzero].join(" ")}>
          {value}
        </div>
      </div>
    )}
  </React.Fragment>
);

const Balance: FC = () => {
  console.log("++rent_sky");
  const [balance, setBalance] = useState<IBalance | undefined>();
  const [availablePayments, setAvailablePayments] = useState<
    IPaymentFor[] | undefined
  >();
  const [accountFrom, setAccountFrom] = useState<IPaymentFor | undefined>();
  const [accountTo, setAccountTo] = useState<IPaymentFor | undefined>();
  const [hint, setHint] = useState("");
  // const [transferNotAvailable, setTransferNotAvailable] =
  //   useState<string | null>(null);
  const [sum, setSum] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    data.getBalance().then(setBalance);
    data.getAvailablePayments().then(setAvailablePayments);
  }, []);

  useEffect(() => {
    if (availablePayments) {
      const accountFrom = availablePayments.find((i) => i.from === true);
      setAccountFrom(accountFrom);
      const accountTo = availablePayments.find((i) => i.to === true);
      setAccountTo(accountTo);
      if (accountTo) {
        setSum(accountTo.min || MIN_SUM);
      }
    }
  }, [availablePayments]);

  useEffect(() => {
    if (accountFrom && accountTo) {
      let hint = "";
      const currentBalance =
        accountFrom.name === "yandex" && balance
          ? balance.sumYandex
          : balance && accountFrom.name === "cityMobil"
          ? balance.cityMobilBalance
          : accountFrom.name === "rent" && balance
          ? balance.sumRent
          : accountFrom.name === "rent_citymobil" && balance
          ? balance.sumRent
          : accountFrom.name === "rent_sky" && balance
          ? balance.sumRent
          : 0;
      if (balance && balance.sumRent < 0 && accountTo.name === "withdraw") {
        // долг за аренду
        hint += `Долг по аренде ${-balance.sumRent} ₽\n`;
      }
      if (currentBalance - sum < 0) {
        // недостаточно средств
        hint +=
          accountFrom.name === "rent"
            ? `Недостаточно средств для возврата.\nСперва пополните счёт аренды\n`
            : `Недостаточно средств: ${currentBalance} ₽\n`;
      }
      if (accountFrom.min_ost > currentBalance - sum) {
        // превышен минимальный остаток
        hint += `Минимальный остаток на счёте: ${accountFrom.min_ost} ₽\n`;
      }
      if ((accountTo.min || MIN_SUM) > sum) {
        // занижен порог
        hint += `Минимальный перевод: ${accountTo.min || MIN_SUM} ₽\n`;
      }
      if (hint) {
        hint = `Данный перевод невозможен\n` + hint;
      }
      setHint(hint);

      availablePayments &&
        accountFrom &&
        setAccountTo(
          availablePayments.find((i) => checkPaymentsAvailable(accountFrom, i))
        );
    }
  }, [balance, accountFrom, accountTo, sum, availablePayments]);

  const accountFromChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (availablePayments) {
      setAccountFrom(availablePayments.find((i) => i.name === e.target.value));
    }
  };

  const accountToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (availablePayments) {
      setAccountTo(
        availablePayments.find((i) => {
          return i.name === e.target.value;
        })
      );
    }
  };

  return (
    <BasicFrame title="Баланс">
      <Dialog title="Перевод средств" show={showDialog}>
        <div className={styles.form}>
          <label>
            Со счёта
            <select
              value={accountFrom && accountFrom.name}
              onChange={accountFromChange}
            >
              {availablePayments &&
                availablePayments
                  .filter((i) => i.from)
                  .map((i) => (
                    <option value={i.name} key={i.name}>{`${i.text} ${
                      balance && i.name === "yandex"
                        ? `(${balance.sumYandex} ₽)`
                        : balance && i.name === "cityMobil"
                        ? `(${balance.cityMobilBalance} ₽)`
                        : balance && i.name === "rent"
                        ? `(${balance.sumRent} ₽)`
                        : balance && i.name === "rent_citymobil"
                        ? `(${balance.sumRent} ₽)`
                        : balance && i.name === "rent_sky"
                        ? `(${balance.sumRent} ₽)`
                        : ""
                    }`}</option>
                  ))}
            </select>
          </label>
          <label>
            На счёт
            <select
              value={accountTo && accountTo.name}
              onChange={accountToChange}
            >
              {availablePayments &&
                availablePayments
                  .filter(
                    (i) =>
                      i.to &&
                      accountFrom &&
                      checkPaymentsAvailable(accountFrom, i)
                  )
                  .map((i) => (
                    <option key={i.name} value={i.name}>
                      {i.text}
                    </option>
                  ))}
            </select>
          </label>
          <label>
            Сумма
            <input
              type="text"
              pattern="\d*"
              value={sum}
              onChange={(e) => setSum(parseInt(e.target.value) || 0)}
            />
          </label>
        </div>
        <div className={styles.hint}>{hint}</div>
        <div className={styles.dialogButtonsContainer}>
          <button onClick={(e) => setShowDialog(false)} className="secondary">
            Отмена
          </button>
          <button
            disabled={!!hint}
            onClick={(e) => {
              if (accountFrom && accountTo) {
                data
                  .transfer({
                    from: accountFrom.name,
                    to: accountTo.name,
                    sum,
                  })
                  .then((result) => {
                    if (result) {
                      setShowDialog(false);
                    }
                  });
                // setTransferNotAvailable("Таймаут между переводами 5 минут");
                // setTimeout(() => setTransferNotAvailable(null), 1000);
              }
            }}
          >
            Перевести
          </button>
        </div>
      </Dialog>
      <div className={styles.container}>
        <div className={styles.datetime}>
          <Icon icon={faInfoCircle} className={styles.icon} />
          {`На ${moment(balance ? balance.timestamp : undefined).format(
            "HH:mm DD.MM.YYYY"
          )}`}
        </div>
        <div className={styles.balance}>
          {balance &&
            _.intersection(Object.keys(balance), Object.keys(balanceDict)).map(
              (k) => (
                // Object.keys(balance).map(k => (
                <Line key={k} title={k} value={balance[k]} />
              )
            )}
          {balance &&
            balance.realLastPay &&
            balance.realLastPay.system === "RENTAL" &&
            balance.realLastPay.cost && (
              <>
                <hr />
                <Line
                  title={`Вывод на карту`}
                  subTitle={`${moment(balance.realLastPay.createdAt).format(
                    "HH:mm DD.MM.YYYY"
                  )}`}
                  value={balance.realLastPay.cost}
                />
              </>
            )}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={(e) => history.goBack()} className="secondary">
          Назад
        </button>
        {account.user && account.user.yandexId && accountFrom && accountTo && (
          <button onClick={(e) => setShowDialog(true)}>Перевести</button>
        )}
      </div>
    </BasicFrame>
  );
};

export default Balance;
