import { observable, action } from "mobx";
import { ui, history } from "./index";

import { AccountAPI as api } from "../api/account";
import { IUser, IBankAccount, IBankBik } from "../models";

class Account {
  @observable isAuth: boolean = false;
  @observable uin: string = "";
  @observable user: IUser | null = null;

  constructor() {
    ui.loading = true;
    api.check()
      .then(result => {
        this.isAuth = true;
        this.user = result;
      })
      .catch(() => {
        history.replace("/pin");
      })
      .finally(() => {
        ui.loading = false;
      });
  }

  @action.bound
  async getPin(uin: string, byPhone: boolean = false) {
    ui.loading = true;
    try {
      const result = await api.getPin(uin);
      if (result.pin) {
        ui.setMessage({ text: `Ваш PIN код ${result.pin}`, lifeTime: 5000 });
      } else {
        ui.setMessage({ text: `Введите полученный PIN код`, lifeTime: 5000 });
      }
      this.uin = uin;
      history.push("/login");
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
  }

  @action.bound
  async changeDriver(uin: string) {
    ui.loading = true;
    try {
      const root = this.user ? this.user.root : undefined;
      this.user = await api.changeDriver(uin);
      if (this.user) {
        this.user.root = root;
      }
      this.uin = uin;
      history.push("/");
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
  }

  @action.bound
  async login(pin: string) {
    ui.loading = true;
    try {
      this.user = await api.login("pin", this.uin, pin);
      this.isAuth = true;
      history.push("/");
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
  }

  @action.bound
  async logout() {
    ui.loading = true;
    try {
      await api.logout();
      this.isAuth = false;
      this.user = null;
      history.push("/pin");
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
  }

  @action.bound
  async setEmail(email: string) {
    ui.loading = true;
    try {
      await api.setEmail(email);
      ui.setMessage({ text: `На адрес ${email} отправлена ссылка для подтверждения.`, lifeTime: 5000, type: "INFO" });
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
  }

  @action.bound
  async getBankDetails(): Promise<IBankAccount[]> {
    let result = null;
    ui.loading = true;
    try {
      result = await api.getBankDetails();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result ? result.data : [];
  }

  @action.bound
  async setBankDetails(account: IBankAccount): Promise<any> {
    let result = null;
    ui.loading = true;
    try {
      result = await api.setBankDetails(account);
      history.goBack();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  @action.bound
  async createBankDetails(account: IBankAccount): Promise<any> {
    let result = null;
    ui.loading = true;
    try {
      result = await api.createBankDetails(account);
      history.goBack();
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  @action.bound
  async getBankByBik(bik: string): Promise<IBankBik | undefined> {
    let result;
    ui.loading = true;
    try {
      result = await api.getBankByBik(bik);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }
}

export const account = new Account();
