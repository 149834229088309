import React, { FC, useRef } from "react";
import moment from "moment";
import { IPlanRecord } from "../../../models";
import { IGrid } from "./Grid";
import styles from "./WeekGrid.module.scss";

interface IWeekGridEl {
  onSelect: Function;
  rootEl?: HTMLDivElement;
  record?: IPlanRecord;
  recordBegin?: IPlanRecord;
  currentDay: moment.Moment;
  row: number;
  k: number;
}

const WeekGridEl: FC<IWeekGridEl> = ({
  onSelect,
  record,
  recordBegin,
  currentDay,
  row,
  k
}) => {
  const el = useRef(null);
  const hue = record ? (Math.sin(record.id) * 1000) % 360 : 0;
  return (
    <div
      className={[
        styles.cell,
        moment().isSame(currentDay, "day") && styles.currentDay
      ].join(" ")}
      ref={el}
      onClick={e => record && onSelect(el, record)}
    >
      {recordBegin && row !== -1 && (
        <div className={styles.record}>
          <div className={styles.title}>{recordBegin.carNom}</div>
        </div>
      )}
      {row === -1 ? `${k / 8}:`.padStart(3, "0") : ""}
      {record && (
        <div
          className={styles.active}
          style={{ filter: `hue-rotate(${hue}deg)` }}
        />
      )}
    </div>
  );
};

const WeekGrid: FC<IGrid> = ({ onSelect, period, records }) => {
  const week = ["", "пн", "вт", "ср", "чт", "пт", "сб", "вс"];
  const start = moment(period.bgn).startOf("week");
  // console.log("render");
  return (
    <div className={styles.calendar}>
      <div className={styles.week}>
        {week.map((i, k) => (
          <div
            key={k}
            className={[
              styles.header,
              start
                .clone()
                .add(k - 1, "day")
                .toJSON() ===
                moment()
                  .startOf("day")
                  .toJSON() && styles.currentDay
            ].join(" ")}
          >
            {i &&
              start
                .clone()
                .add(k - 1, "day")
                .date()}
            {i}
          </div>
        ))}
        {[...Array(24 * 8)].map((i, k) => {
          const row = (k % 8) - 1;
          const col = Math.floor(k / 8);
          const currentCell = start
            .clone()
            .add(col, "hour")
            .add(row, "day");
          const recordBegin = records.find(
            i =>
              moment(i.openedAt).format("DD/MM/YYYY HH") ===
              currentCell.format("DD/MM/YYYY HH")
          );
          const record = records.find(
            i =>
              currentCell >= moment(i.openedAt) &&
              currentCell < moment(i.closedAt)
          );
          return (
            <WeekGridEl
              onSelect={onSelect}
              key={k}
              record={record}
              recordBegin={recordBegin}
              currentDay={currentCell}
              row={row}
              k={k}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(WeekGrid, (prevProps: IGrid, nextProps: IGrid) => {
  return (
    prevProps.records.length === nextProps.records.length &&
    prevProps.period.bgn === nextProps.period.bgn
  );
});
