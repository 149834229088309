import React, { FC, useState, useEffect } from "react";
import SubMenu from "../../components/SubMenu";
import { data } from "../../store";
import _ from "lodash";

const ORDERS_SUB_MENU = [
  { title: "Выполненные", path: "/orders/history" },
  { title: "Назначенные", path: "/orders/reserved/" },
  { title: "Выбор заказов", path: "/orders/reserved/requests" }
];

const OrdersMenu: FC = () => {
  const [menu, setMenu] = useState(ORDERS_SUB_MENU);
  const [reservedOrders, setReservedOrders] = useState<any[]>([]);

  useEffect(() => {
    data.getReservedOrders(false, true).then(setReservedOrders);
    // setReservedOrders([]);
  }, []);

  useEffect(() => {
    if (reservedOrders && reservedOrders.length) {
      const _menu = _.cloneDeep(menu);
      _menu[1].title = `${ORDERS_SUB_MENU[1].title} (${reservedOrders.length})`;
      setMenu(_menu);
    }
    // eslint-disable-next-line
  }, [reservedOrders]);

  return <SubMenu menu={menu} />;
};

export default OrdersMenu;
