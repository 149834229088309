import React, { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { account, ui } from "../store";
import { faBars, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import styles from "./BasicFrame.module.scss";

interface IHeader {
  title: string;
}

const Header: FC<IHeader> = observer(({ title }) => {
  const overUser =
    account.user &&
    account.user.root &&
    account.user.id !== (account.user.root && account.user.root.id);
  const driver = overUser ? account.user && account.user.person : false;
  return (
    <div className={styles.headerContainer}>
      <div className={[styles.header, !ui.online && styles.blink].join(" ")}>
        <div className={styles.title}>{title}</div>
        {driver && (
          <div className={styles.subtitle}>{`${account.user &&
            account.user.call} ${driver.fio}`}</div>
        )}
        <Link to="/" className={styles.linkContainer}>
          {!ui.online && <Icon icon={faWifi} className={styles.blinkIcon} />}
          <Icon icon={faBars} className={styles.icon} />
        </Link>
      </div>
    </div>
  );
});

interface IBasicFrame extends IHeader {
  children: any;
}

const BasicFrame: FC<IBasicFrame> = ({ children, title }) => (
  <div className={styles.container}>
    <Header title={title} />
    <div className={styles.main}>{children}</div>
  </div>
);

export default BasicFrame;
