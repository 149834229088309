import React, { FC } from "react";
import moment from "moment";
import {
  faChevronCircleRight as rightIcon,
  faChevronCircleLeft as leftIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { IPeriod } from "../../../models";

import styles from "./Title.module.scss";

interface ITitle {
  currentPeriod: IPeriod;
  next: Function;
  prev: Function;
}

const Title: FC<ITitle> = ({ currentPeriod, next, prev }) => {
  const title = (currentPeriod: IPeriod): string => {
    const bgn = moment(currentPeriod.bgn);
    const end = moment(currentPeriod.end);
    const duration = moment.duration(end.diff(bgn));
    let result = "";
    if (duration.weeks() > 1) {
      result = `${bgn.format("MMMM YYYY")}`;
    } else {
      result =
        bgn.year() === end.year()
          ? `${bgn.format("DD MMM")} - ${end.format("DD MMM YYYY")}`
          : `${bgn.format("DD MMM YYYY")} - ${end.format("DD MMM YYYY")}`;
    }

    return result;
  };
  return (
    <div className={styles.title}>
      <div className={styles.datetime}>{title(currentPeriod)}</div>
      <div className={styles.arrows}>
        <div onClick={e => prev()}>
          <Icon icon={leftIcon} />
        </div>
        <div onClick={e => next()}>
          <Icon icon={rightIcon} />
        </div>
      </div>
    </div>
  );
};

export default Title;