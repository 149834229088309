import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { IBankAccount, IBankBik } from "../../models";
import { history, account } from "../../store";
import BasicFrame from "../../components/BasicFrame";

import styles from "./index.module.scss";
import { Dictionary } from "lodash";

interface IParams {
  id: string;
}

const recipientTypes: Dictionary<any> = {
  BANK: "Банк",
  SELF: "Водитель",
  OTHER: "Третье лицо"
};

const BankAccountForm: FC<RouteComponentProps<IParams>> = ({ match }) => {
  const id = parseInt(match.params.id) || 0;
  const [bankAccount, setBankAccount] = useState<IBankAccount | undefined>({
    id: id,
    bankCardNumber: "",
    bankAccountNumber: "",
    bankPurpose: "",
    recipientName: account.user ? account.user.person.fio : "",
    recipientType: "SELF",
    remarks: "",
    verified: false,
    active: false,
    driverTitle: account.user ? account.user.person.fio : "",
    bankTitle: "",
    bankId: 0,
    bankBik: ""
  });
  const [bank, setBank] = useState<IBankBik>();

  useEffect(() => {
    if (id) {
      account.getBankDetails().then(result => {
        const bankAccount = result.find(i => i.id === id);
        setBankAccount(bankAccount);
        if (bankAccount) {
          account
            .getBankByBik(bankAccount.bankBik)
            .then(result => setBank(result));
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (account.user && bankAccount && !bankAccount.recipientName) {
      setBankAccount({
        ...bankAccount,
        recipientName: account.user.person.fio,
        driverTitle: account.user.person.fio
      });
    }
  }, [bankAccount]);

  const onChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (bankAccount) {
      const { value, name } = e.target;
      if (name === "recipientType") {
        bankAccount.recipientName =
          value === "SELF"
            ? bankAccount.driverTitle
            : value === "BANK"
            ? bankAccount.bankTitle
            : "";
      } else if (name === "bankBik" && value.length === 9) {
        account.getBankByBik(value).then(result => {
          if (result) {
            setBank(result);
            setBankAccount({
              ...bankAccount,
              bankId: result.id,
              bankBik: result.bik,
              bankTitle: result.title
            });
          }
        });
      }
      setBankAccount({ ...bankAccount, [name]: value });
    }
  };

  return (
    <BasicFrame title={`Cчёт №${id}`}>
      <div className={styles.container}>
        <label>
          БИК
          <input
            type="text"
            name="bankBik"
            disabled={!!id}
            value={bankAccount && bankAccount.bankBik}
            onChange={onChange}
          />
          {bank && (
            <div className={styles.bank}>
              <div>Счёт: {bank.corrAccount}</div>
              <div
                dangerouslySetInnerHTML={{ __html: `Название: ${bank.title}` }}
              ></div>
            </div>
          )}
        </label>
        <label>
          Номер счёта
          <input
            type="text"
            name="bankAccountNumber"
            value={bankAccount && bankAccount.bankAccountNumber}
            onChange={onChange}
          />
        </label>
        <label>
          Номер карты
          <input
            type="text"
            name="bankCardNumber"
            value={bankAccount && bankAccount.bankCardNumber}
            onChange={onChange}
          />
        </label>
        <label>
          Тип получателя
          <select
            name="recipientType"
            value={
              bankAccount
                ? bankAccount.recipientType
                : Object.keys(recipientTypes)[0]
            }
            onChange={onChange}
          >
            {Object.keys(recipientTypes).map(i => (
              <option key={i} value={i}>
                {recipientTypes[i]}
              </option>
            ))}
          </select>
        </label>
        <label>
          Получатель
          <input
            type="text"
            name="recipientName"
            disabled={
              bankAccount ? bankAccount.recipientType !== "OTHER" : false
            }
            value={bankAccount && bankAccount.recipientName}
            onChange={onChange}
          />
        </label>
        <label>
          Назначение платежа
          <textarea
            name="bankPurpose"
            value={bankAccount && bankAccount.bankPurpose}
            onChange={onChange}
          />
        </label>
        <label>
          Комментарий
          <textarea
            name="remarks"
            value={bankAccount && bankAccount.remarks}
            onChange={onChange}
          />
        </label>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button
          onClick={e => {
            if (bankAccount) {
              if (id) {
                account.setBankDetails(bankAccount);
              } else {
                account.createBankDetails(bankAccount);
              }
            }
          }}
        >
          Сохранить
        </button>
      </div>
    </BasicFrame>
  );
};

export default BankAccountForm;
