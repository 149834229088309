import React, { FC, useState } from "react";

import { history, messages, ui } from "../../store";
import BasicFrame from "../../components/BasicFrame";

import styles from "./index.module.scss";

const EmailDirector: FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <BasicFrame title="Письмо директору">
      <div className={styles.container}>
        <div className={styles.form}>
          <input
            type="text"
            placeholder="EMAIL для связи"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <textarea
            className={styles.textarea}
            placeholder="Текст сообщения"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button
          onClick={e =>
            messages.sendEmail(email, message).then(result => {
              history.goBack();
              ui.setMessage({
                text: "Ваша заявка успешно отправлена"
              });
            })
          }
        >
          Отправить
        </button>
      </div>
    </BasicFrame>
  );
};

export default EmailDirector;
