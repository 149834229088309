import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import { history, messages, data, ui } from "../../store";
import BasicFrame from "../../components/BasicFrame";

import styles from "./index.module.scss";
import { ICar } from "../../models";

const EmailMech: FC = () => {
  const [cars, setCars] = useState<ICar[] | undefined>();
  const [car, setCar] = useState<ICar | undefined>();
  useEffect(() => {
    data.getCars().then(cars => {
      setCar(cars ? cars[0] : undefined);
      setCars(cars);
    });
  }, []);
  const [message, setMessage] = useState();
  return (
    <BasicFrame title="Заявка на ремонт">
      <div className={styles.container}>
        <div className={styles.form}>
          <select
            onChange={e => {
              if (cars) {
                const car = cars.find(i => i.id === parseInt(e.target.value));
                if (car) {
                  setCar(car);
                }
              }
            }}
          >
            {cars &&
              cars.map(i => (
                <option key={i.id} value={i.id}>{`${i.model} ${
                  i.regNo
                }`}</option>
              ))}
          </select>
          <textarea
            className={styles.textarea}
            placeholder="Текст сообщения"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button
          onClick={e =>
            car &&
            messages.sendMech(
              `${moment().format("DD.MM.YYYY")} ${car.regNo}`,
              `Автомобиль: ${car.color} ${car.model} ${
                car.regNo
              }\nСообщение: ${message}`
            ).then(result => {
              history.goBack();
              ui.setMessage({
                text: "Ваша заявка успешно отправлена"
              });
            })
          }
        >
          Отправить
        </button>
      </div>
    </BasicFrame>
  );
};

export default EmailMech;
