// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import qs from "qs";
import gConfig from "./config";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // add push listener

    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(
      (process as { env: { [key: string]: string } }).env.PUBLIC_URL,
      window.location.href
    );
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit https://bit.ly/CRA-PWA"
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // PUSH
      registration.pushManager.getSubscription().then(sub => {
        if (sub === null) {
          // Update UI to ask user to register for Push
          console.log("Not subscribed to push service!");
          console.log("SUBSCRIBE_USER_TO_PUSH", gConfig.SUBSCRIBE_USER_TO_PUSH);
          //! пока не регистрируем
          if (gConfig.SUBSCRIBE_USER_TO_PUSH) {
            subscribeUser();
          }
        } else {
          // We have a subscription, update the database
          sendSubObjectToServer(sub);
        }
      });
      // =================
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://bit.ly/CRA-PWA."
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      sendRejectSubInfoToServer(error.message);
      console.error("Error during service worker registration:", error);
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

// подписка на PUSH
function subscribeUser() {
  console.log("i AM subscribeUser");
  return request({
    path: "user/vapidPublicKey"
  }).then(result => {
    const convertedVapidKey = urlBase64ToUint8Array(result.vapidPublicKey);
    return navigator.serviceWorker.ready.then(function(reg) {
      return reg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        })
        .then(function(sub: any) {
          sendSubObjectToServer(sub);
        })
        .catch(function(e) {
          sendRejectSubInfoToServer(e.message);
          if (Notification.permission === "denied") {
            console.warn("Permission for notifications was denied");
          } else {
            const ex = new Error();
            console.error("Unable to subscribe to push", e, ex.stack);
          }
        });
    });
  });
}

function sendSubObjectToServer(subscription: any) {
  request({
    path: "user/registerPushSubscription",
    data: subscription,
    method: "POST"
  }).then(function(result) {
    document.dispatchEvent(
      new CustomEvent("subscriptionDataLoaded", {
        detail: subscription
      })
    );
  });
}

function sendRejectSubInfoToServer(data: any) {
  request({
    path: "user/rejectPushSubscription",
    method: "POST",
    data: { message: data }
  });
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

interface TRequest {
  path?: string;
  query?: any;
  method?: string;
  data?: any;
}

export function request(
  { path, query, method, data }: TRequest = {
    path: "",
    query: {},
    method: "GET",
    data: null
  }
) {
  return fetch(`https://driver.taxi-vsegda.ru/${path}${qs.stringify(query)}`, {
    method,
    credentials: "include",
    headers: {
      "Content-type": "application/json"
    },
    body:
      method && ["POST", "PUT"].includes(method) ? JSON.stringify(data) : null
  }).then(result => result.json());
}
