import React, { FC } from "react";
import { IMode } from "../../../models";
import styles from "./Toolbar.module.scss";


interface IToolbar {
  currentMode: IMode;
  toggleMode: (mode: IMode) => any;
}

const Toolbar: FC<IToolbar> = ({ currentMode, toggleMode }) => (
  <div className={styles.toolbar}>
    <div className={styles.tabs}>
      <div
        className={currentMode === "month" ? styles.active : ""}
        onClick={e => toggleMode("month")}
      >
        Месяц
      </div>
      <div
        className={currentMode === "week" ? styles.active : ""}
        onClick={e => toggleMode("week")}
      >
        Неделя
      </div>
    </div>
  </div>
);

export default Toolbar;