import React, { FC } from "react";
import { IBankAccount } from "../../../models";
import { history, ui } from "../../../store";

import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import styles from "./BankAccount.module.scss";

const panText = (
  text: string,
  symbol: string = "X",
  view: number = 4
): string => {
  if (!text) {
    return '????';
  }
  let result = text;
  const len = text.length;
  result =
    text.slice(0, view) + symbol.repeat(len - view * 2) + text.slice(-view);
  return result;
};

const BankAccount: FC<IBankAccount> = ({
  id,
  bankTitle,
  active,
  verified,
  bankCardNumber,
  bankAccountNumber
}) => (
  <div
    className={styles.container}
    onClick={e =>
      active && verified
        ? ui.setMessage({
            text:
              "Нельзя редактировать текущий активный счет, для его изменения обратитесь к менеджеру",
            type: "WARN"
          })
        : history.push(`/bankAccount/${id}`)
    }
  >
    <div className={styles.info}>
      <div className={styles.title}>{bankTitle}</div>
      {active && verified && (
        <div className={styles.active}>(Текущий счёт)</div>
      )}
      {bankCardNumber ? (
        <div className={styles.card}>
          Номер карты: {panText(bankCardNumber, "X", 4)}
        </div>
      ) : (
        <div className={styles.card}>
          Номер счёта: {panText(bankAccountNumber, "*", 6)}
        </div>
      )}
    </div>
    <div className={styles.action}>
      {verified ? (
        <Icon icon={faCheck} className={styles.green} />
      ) : (
        <Icon icon={faEdit} />
      )}
    </div>
  </div>
);

export default BankAccount;
