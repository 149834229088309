import { action } from "mobx";
import { ui } from "./index";

import { MessageAPI as api } from "../api/messages";

class Messages {
  @action.bound
  async sendEmail(email: string, message: string): Promise<any> {
    ui.loading = true;
    let result = null;
    try {
      result = await api.sendEmail(email, message);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async sendTask(subject: string, message: string): Promise<any> {
    ui.loading = true;
    let result = null;
    try {
      result = await api.sendTask(subject, message);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }

  async sendMech(subject: string, message: string): Promise<any> {
    ui.loading = true;
    let result = null;
    try {
      result = await api.sendMech(subject, message);
    } catch (err) {
      ui.setMessage({ text: err.message, lifeTime: 5000, type: "ERROR" });
      console.error(err);
    }
    ui.loading = false;
    return result;
  }
}

export const messages = new Messages();
