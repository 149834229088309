import React, { FC, useEffect, useState } from "react";

import { history, account } from "../../store";
import { IBankAccount } from "../../models";
import BasicFrame from "../../components/BasicFrame";
import BankAccount from "./components/BankAccount";

import styles from "./index.module.scss";


const BankDetails: FC = () => {
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>();
  useEffect(() => {
    account.getBankDetails().then(result => setBankAccounts(result));
  }, []);
  return (
    <BasicFrame title="Реквизиты">
      <div className={styles.container}>
        {bankAccounts &&
          bankAccounts.map((i, k) => <BankAccount key={k} {...i} />)}
      </div>
      <div className={styles.buttonsContainer}>
        <button onClick={e => history.goBack()} className="secondary">
          Назад
        </button>
        <button onClick={e => history.push("/bankAccount/0")}>
          Добавить
        </button>
      </div>
    </BasicFrame>
  );
};

export default BankDetails;
